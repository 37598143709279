// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {

  apiKey: "AIzaSyDOkvjD_uyx_SN1BBvF4oK3taX3HX6SdrY",

  authDomain: "pcyi-69a05.firebaseapp.com",

  databaseURL: "https://pcyi-69a05-default-rtdb.firebaseio.com",

  projectId: "pcyi-69a05",

  storageBucket: "pcyi-69a05.appspot.com",

  messagingSenderId: "81501565742",

  appId: "1:81501565742:web:42fa462196201e4398c0bf",

  measurementId: "G-71M3P56WJT"

};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };